.typing-effect {
    height: 1.5em;
    /* Set a fixed height, adjust as needed */
    overflow: hidden;
    /* Hide any overflow content */
    display: flex;
    align-items: center;
    /* Center the text vertically */
}

.glow {
    color: rgba(0, 0, 0, 0.8);
    /* Example glow color */
    text-shadow: 0 0 5px rgba(206, 206, 206, 0.8);
    /* Example glow effect */
}

@keyframes glowAnimation {
    0% {
        font-size: larger;
        text-shadow: 0 0 3px rgb(177, 201, 239), 0 0 6px rgb(177, 201, 239);
    }

    100% {
        color: inherit;
        text-shadow: none;
    }
}