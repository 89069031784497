.modal-text-box {
    position: absolute;
    top: 100%;
    /* Position below the list item */
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    color: #333;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1;
    white-space: nowrap;
}

.hover-target {
    padding: 10px;
    background-color: #87ceeb;
    border-radius: 5px;
    cursor: pointer;
}