body {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #f1f1f1;
    color: #3c3c3c;
    margin: 0;
    padding: 0;
}

.App {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #d6d9dc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

header,
footer,
nav,
section {
    margin-bottom: 20px;
    padding: 10px;
    background-color: #ffffff;
    border: 1px solid #e1e1e1;
    border-radius: 4px;
}

h1 {
    font-size: 1.6em;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ffffff;
    /* White border */
    border-radius: 5px;
    background-color: #87ceeb;
    /* Blue background */
    color: #ffffff;
    /* White text */
    display: block;
    /* Make the box stretch to full width */
    width: 100%;
    /* Ensure full width alignment */
    box-sizing: border-box;
    /* Include padding and border in the element's total width */
}

ul {
    list-style-type: none;
    padding: 0;
}

a {
    color: #0077cc;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

.quote {
    font-style: italic;
    color: #555;
    border-left: 3px solid #e1e1e1;
    padding-left: 10px;
    margin: 10px 0;
}

.main-nav {
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.main-nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-around;
}

.main-nav li {
    padding: 10px;
}

.main-nav a {
    text-decoration: none;
    color: #333;
}

.main-nav a:hover {
    text-decoration: underline;
}


li {
    margin-bottom: 8px;
    padding: 15px;
    /* Increased padding for better spacing */
    border: 1px solid #87ceeb;
    border-radius: 5px;
    background-color: #ffffff;
    transition: box-shadow 0.3s ease;
    width: auto;
    /* Ensure width is flexible */
}

li a {
    color: #3c3c3c;
}

li:hover {
    box-shadow: 0 0 15px rgba(0, 119, 204, 0.8);
}

.intro {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}


.quote {
    font-size: 1.2em;
    font-style: italic;
    color: #555;
    border-left: 4px solid #87ceeb;
    padding-left: 15px;
    margin: 20px 0;
    background-color: #f9f9f9;
    padding: 10px 15px;
    border-radius: 5px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}

.profile-block {
    display: flex;
    align-items: center;
    background-color: #87ceeb;
    /* Sky blue background */
    color: #ffffff;
    padding: 20px;
    /* Increased padding for more space */
    border-radius: 10px;
    /* Slightly larger border radius for a softer look */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.profile-icon {
    width: 100px;
    /* Adjust size as needed */
    height: 100px;
    /* Adjust size as needed */
    border-radius: 50%;
    object-fit: cover;
    /* Ensures the image covers the area without distortion */
    object-position: center;
    /* Centers the image within the circle */
    margin-right: 20px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.profile-details {
    display: flex;
    flex-direction: column;
}

.profile-name {
    font-size: 1.8em;
    /* Slightly larger font size */
    font-weight: bold;
    margin: 0;
}

.profile-motto {
    font-size: 1.1em;
    /* Slightly larger font size */
    margin: 0;
    opacity: 0.8;
}

.footer {
    text-align: center;
    padding: 20px;
    background-color: #f1f1f1;
    /* Adjust as needed */
}

.footer-content a {
    color: #333;
    /* Adjust as needed */
    text-decoration: none;
    margin: 0 10px;
    display: inline-flex;
    align-items: center;
}

.footer-content a:hover {
    text-decoration: underline;
}

.footer-content svg {
    margin-right: 5px;
    /* Space between icon and text */
}

/* Media query for mobile devices */
@media (max-width: 600px) {
    .App {
        padding: 10px;
        margin: 10px;
    }

    header,
    footer,
    nav,
    section {
        padding: 5px;
        margin-bottom: 10px;
    }

    h1 {
        font-size: 1.4em;
        padding: 5px;
    }

    .main-nav ul {
        flex-direction: column;
        align-items: center;
    }

    .main-nav li {
        padding: 5px;
    }

    .profile-block {
        flex-direction: column;
        align-items: center;
        padding: 10px;
    }

    .profile-icon {
        width: 80px;
        height: 80px;
        margin-right: 0;
        margin-bottom: 10px;
    }

    .profile-name {
        font-size: 1.5em;
    }

    .profile-motto {
        font-size: 1em;
    }

    .footer {
        padding: 10px;
    }

    .footer-content a {
        margin: 0 5px;
    }
}